.footer__container {
  background-color: $blue;
  color: $light-gray;
  padding: 2rem calc(4rem - 16px);

  @include breakpoint(medium only) {
    padding: 1rem calc(1.5rem - 7px);
  }

  @include breakpoint(small only) {
    padding: 2rem calc(1.5rem - 7px);
  }

  .footer__files {
    @include breakpoint(small only) {
      margin-bottom: 15px;
    }
  }

  .footer__text, .footer__links {
    margin-bottom: 0;
    padding: 8px 0;

    @include breakpoint(small only) {
      padding: 3px 0;
    }

    .fas {
      padding: 0 15px;
    }
  }

  .footer__text {
    font-size: 12px;

    a {
      color: $white;
    }
  }

  .footer__links {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .footer__anchor {
    color: $white;
    padding-right: 30px;
    text-transform: uppercase;
  }
}