.body {
  margin: 0 auto;
  font-family: $body-font-family;
}

.sticky {
  z-index: 5;
}

.l-container {
  margin: 0 auto;
}

.l-image {
  position: relative;
  height: 100%;
  background-size: cover;
}

.l-image__header {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 18%;
  width: auto;
  padding: 2.3rem calc(4rem - 16px) 0;
  background-color: $blue;
  z-index: 3;

  @include breakpoint(medium down) {
    padding: 2rem;
  }

  .l-image__title {
    color: $white;

    @include breakpoint(small only) {
      font-size: 24px;
    }

    .fas {
      padding-right: 15px;
      color: $green;
    }
  }

  .l-image__subtitle {
    font-size: 80%;
    color: $light-gray;
  }
}

.l-section {
  padding: 2.3rem calc(4rem - 16px) 0.6rem;

  @include breakpoint(medium down) {
    padding: 2rem calc(1.5rem - 7px) 0.5rem;
  }
}

.l-section__small-text {
  margin-bottom: 0;
}

.l-section__header {
  color: $blue;
  margin-bottom: 25px;
  text-transform: uppercase;

  @include breakpoint(small only) {
    font-weight: 600;
  }
}

.l-section__header--light {
  color: $white;
}

.l-section__header--contact {
  padding: 2.3rem calc(4rem - 16px) 0;

  @include breakpoint(medium down) {
    padding: 2rem calc(1.5rem - 7px) 0;
  }
}

.l-section--relative {
  position: relative;
}

.l-text__container {
  width: 70%;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;

  @include breakpoint(small only) {
    width: 100%;
  }
}

.u-extra-margin--bottom {
  margin-bottom: 15px;
}

.tabs-content--fix {
  min-height: 100px;
  height: auto;
  margin-bottom: 30px;
}

.u--margin {
  margin: 100px 0 30px 0;

  @include breakpoint(small only) {
    margin: 130px 0 30px 0;
  }
}

.u--margin2 {
  margin-top: 40px;

  @include breakpoint(small only) {
    margin-top: 30px;
  }
}

.u--margin-bottom {
  margin-bottom: 30px;
}

.u-zero-margin--bottom {
  margin-bottom: 0;
}

.u-padding-top {
  padding-top: 20px;
}

.u-padding-bottom {
  padding-bottom: 20px;
}
