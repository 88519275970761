@include breakpoint(small only) {
  input[type=text] {
    height: 3rem;
  }
}

.form__container .grid-container {
  padding: 0;
}

.form__container .cell {
  margin-bottom: 0.8rem;
}

.form__container {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 3rem;
  padding-top: 3rem;

  @include breakpoint(small only) {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .cell--last {
    margin-bottom: 0;
  }
}

.form__star {
  color: $green;
  font-weight: 700;
  padding-left: 2px;
}

.form__button {
  padding: 0.8rem 1.5rem;
  width: 140px;
  background-color: $green;
  color: $white;
  font-weight: 600;
  &:hover {
    background-color: darken($green, 5%);
    color: $white;
  }
}

.msg__info {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: $white;
  color: $blue;
  text-align: center;
  font-size: 1.2rem;

  p {
    margin-bottom: 0
  }
}