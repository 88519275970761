.side-nav__container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin-left: 15px;
  background-color: $blue;
  text-align: left;

  .side-nav__list {
    list-style-type: none;
    margin: 0;
  }

  .side-nav__item {
    padding: 13px 30px;

    &:hover {
      background-color: $green;
    }
  }
  .side-nav__link {
    color: $white;
    font-weight: 600;
  }
}