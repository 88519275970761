// PRODUCT INFO TABLE

.l-table {
  text-align: left;
  width: auto;
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 90%;
}

.table__cell {
  border: 1px solid $light-gray;
  padding: 10px;
  max-width: 180px;
}

.table__head {
  min-width: 50px;
  padding: 10px;
  background-color: $green;
  color: $white;
  font-weight: 600;
  border: 1px solid $green;
}

.info__table--bottom {
  @include breakpoint(medium down) {
    padding-bottom: 30px;
  }
}

// RESET INLINE STYLING IN THE HEAD TAG

.l-contact-bar, nav, .l-hero__container, .about__container, .contact__container--blue, #topbar-responsive, .menu__dropdown {
  visibility: visible !important;
}

@media screen and (min-width: 1160px) {
  .side-nav__container {
    visibility: visible !important;
  }
}