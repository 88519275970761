.contact__container {
  background-color: $green;
  padding-bottom: 2rem;
  opacity: 1;

  @include breakpoint(small only) {
    padding-bottom: 0;
    font-size: 1.2rem;
  }

  .contact__columns {
    width: 70%;
    margin: 0 auto;
    padding-left: 3rem;
    color: $white;

    @media screen and (max-width: 820px) {
      width: 100%;
      padding: 30px 2rem;
    }
  }

  .contact__logo {
    padding: 30px;
    background-color: $white;
  }

  .contact__clock {
    width: 20px;
  }

  .contact__link {
    color: $white;

    &:hover {
      color: $light-gray;
    }
  }
}

.contact__container--blue {
  background-color: $blue;
}
