.no-webp .hero__image {
  background-image: url('../img/main-hero.jpg');

  @include breakpoint(small only) {
    background-image: url("../img/main-hero-small.jpg");
  }

  @include breakpoint(medium only) {
    background-image: url("../img/main-hero-medium.jpg");
  }
}

.webp .hero__image {
  background-image: url('../img/webp/main-hero.webp');

  @include breakpoint(small only) {
    background-image: url("../img/webp/main-hero-small.webp");
  }

  @include breakpoint(medium only) {
    background-image: url("../img/webp/main-hero-medium.webp");
  }
}

.l-hero__container {
  position: relative;
  height: 385px;
  margin: 0;
  padding: 0;

  .hero__image {
    background-size: cover;
    height: 100%;
  }

  .hero__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(33, 65, 112, 0.5);
    z-index: 2;
  }

  .hero__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.2rem 2rem;
    z-index: 3;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.6;
    background-color: $green;
    color: $white;

    &:hover {
      color: $green;
      background-color: $white;
    }

    &:focus {
      color: $white;
    }

    @include breakpoint(small only) {
      width: 80%;
    }

    .hero__anchor {
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.6;
    }
  }
}