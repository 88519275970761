.no-webp .subpage__image {
    background-image: url('../img/subpage-1600x500.jpg');

    @include breakpoint(medium only) {
        background-image: url('../img/subpage-1200.jpg');
    }
    @include breakpoint(small only) {
        background-image: url('../img/subpage-700.jpg');
    }
}

.webp .subpage__image {
    background-image: url('../img/webp/subpage-1600x500.webp');

    @include breakpoint(medium only) {
        background-image: url('../img/webp/subpage-1200.webp');
    }
    @include breakpoint(small only) {
        background-image: url('../img/webp/subpage-700.webp');
    }
}

.no-js .subpage__image {
    background-image: url('../img/subpage-1600x500.jpg');

    @include breakpoint(medium only) {
        background-image: url('../img/subpage-1200.jpg');
    }
    @include breakpoint(small only) {
        background-image: url('../img/subpage-700.jpg');
    }
}
