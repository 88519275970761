.l-contact-bar {
  padding: 0 4rem;
  height: 30px;
  background-color: $green;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;

  @include breakpoint(medium down) {
    padding: 0 calc(1.5rem - 7px);
  }

  .contact-bar__item {
    padding: 0;
    margin: 0 25px 0 0;
    color: $white;
    a {
      color: $white;
    }
    .fas {
      margin-right: 10px;
    }
  }

  .contact-bar__item--last {
    margin-right: 0;
  }

  .contact-bar__item--mobile {
    @include breakpoint(small only) {
      margin-right: 0;
    }
  }
}