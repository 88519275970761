$topbar-responsive-animation-type: fade-in;


.topbar-responsive {
  background: $white;
  border-bottom: 1px solid $light-gray;

  @include breakpoint(medium down) {
    padding: 0.75rem calc(1.5rem - 7px);
  }

  .top-bar-title {
    display: flex;
    justify-content: space-between;

    .title-bar {
      padding: 0;
      font-size: 1.5rem;

      .fas {
        color: $blue;
      }

      .menu__icon {
        cursor: pointer;
      }
    }
  }

  .topbar-responsive__logo {
    vertical-align: middle;
    padding-left: 0;
  }

  .top-bar__logo {
    width: 195px;
  }

  .menu {
    background: $white;

    .menu__item {
      padding: 5px 0 5px 20px;
      transition: all 0.4 ease-in;

      @include breakpoint(small only) {
        margin-bottom: 10px;
        padding: 10px 0;

        &:hover {
          border-left: 2px solid $green;
        }
      }

      @include breakpoint(medium only) {
        padding-left: 0;
      }

      &:hover > .menu__link {
        border-bottom: 2px solid $green;

        @include breakpoint(small only) {
          padding-left: 12px;
          border-color: $white;
        }
      }

      &:hover > .menu__link--noborder {
        border-bottom: 2px solid #fff;

        @include breakpoint(small only) {
          border-color: $white;
        }
      }
    }
  }
    .menu__item--first {
      padding-left: 0 !important;
    }

    .menu__item--last {
      margin-right: 0;
      padding-right: 0;

      &:hover > .menu__link--last {
        color: $green;
      }

      @include breakpoint(medium only) {
        padding-left: 20px !important;
      }

    }

    .menu__link {
      padding: 0;
      color: $blue;
      font-weight: 600;
      border-bottom: 2px solid #fff;
      transition: all 0.15s ease-in;

      &:hover {
        color: $blue;
      }
    }

    .menu__link--last  {
      color: $green;
      border: 2px solid $green;
      background-color: $white;

      @include breakpoint(small only) {
        border: 2px solid $white;
      }
    }

    .menu__dropdown {
      color: $blue;
    }

    .is-dropdown-submenu {
      border-top-color: $white;
      top: 15px;
    }

    .menu__dropdown-link {
      &:first-of-type {
        @include breakpoint(small only) {
          margin-top: 8px;
        }
      }

      &:hover {
        background-color: $green;
        font-weight: 600;
        padding-left: 10px;
        transition: all 0.5s ease-in;
      }
      &:hover > a {
        color: $white;
        transition: color 0.5s ease-in;
      }
    }

  .is-dropdown-submenu {
    z-index: 10;
  }

  .accordion-menu {
    @include breakpoint(medium down) {
      margin-top: 30px;
    }
  }

  @include breakpoint(small only) {
    .top-bar-title {
      position: relative;
      width: 100%;
    }
  }
}

.topbar-responsive--on-contact {
  padding: 15px 4rem;
}

@include breakpoint(small only) {
  .topbar-responsive-links {
    animation-fill-mode: both;
    animation-duration: 1s;
    width: 100%;

    @if $topbar-responsive-animation-type == slide-down {
      animation: slideDown 1s ease-in;
    } @else if $topbar-responsive-animation-type == fade-in {
      animation: fadeIn 1s ease-in;
    } @else {
      animation: none;
    }
  }
}

.dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
  right: 50%;
  left: 10%;
}

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 51px;
}

.is-stuck > .topbar-responsive {
  padding-top: 0;
  padding-bottom: 0;
}

.is-anchored {
  padding: 15px calc(4rem - 16px);
  @include breakpoint(medium down) {
    padding-left: calc(1.5rem - 7px);
    padding-right: calc(1.5rem - 7px);
  }
}

.is-stuck {
  padding: 0 calc(4rem - 16px) ;
  @include breakpoint(medium down) {
    padding-left: calc(1.5rem - 7px);
    padding-right: calc(1.5rem - 7px);
  }
}
