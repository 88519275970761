.info__container {
  padding-top: 0;
  min-height: 200px;
}

.product__nav {
  margin-bottom: 40px;
  color: $green;
  font-size: 12px;
  font-weight: 600;

  .fas {
    padding: 0 15px;
  }
}

.product__subtitle {
  margin-bottom: 0;
  padding-bottom: 30px;
  padding-left: 0;
  color: $blue;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.product__subtitle--padding-top {
  @include breakpoint(medium down) {
    padding-top: 40px;
  }
}

.product__list-container {
  background-color: $white;

  .product__list {
    margin-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .product__list-item {
    padding-left: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 90%;
    color: $blue;

    .fas {
      padding-right: 20px;
      color: $green;
      font-size: 90%;
    }
  }
}

.product__important {
  margin: 40px 0 60px;
  color: $blue;
  justify-content: space-between;

  .product__squares {
    margin-right: 20px;
  }
}

.product__important-text {
  padding-top: 15px;
  margin-bottom: 0;
  font-weight: 700;
}

.product__type {
  min-width: 130px;
  padding: 15px 20px;
  margin: 0 15px 20px;
  background-color: $green;
  color: $white;
  border: 1px solid $green;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    background-color: darken($green, 5%);
    > a {
      color: $white;
    }
  }

  &:hover > .product__tab-anchor,
  .product__tab-anchor .is-active,
  .tabs-title > a:focus,
  .tabs-title > a[aria-selected='true'] {
    background-color: darken($green, 5%);
    color: $white;
  }

  .product__tab-anchor {
    color: $white;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: $green;
    transition: all 0.2s ease-in;
    &:hover {
      color: $white;
    }
  }

  .product__tab-anchor:focus {
    background-color: darken($green, 5%);
    color: $white;
  }

  .product__tab-anchor[aria-selected="true"] {
    background-color: $white;
    color: $green;
    transition: all 0.2s ease-in;
  }
}

.tabs-title {
  &:hover > .product__type-anchor {
    color: $white;
  }
}

.tabs-title .product__type .is-active {
    background-color: $white;
    color: $green;
    border: 1px solid $green;
}

.product__tabs .is-active {
  background-color: $white;
}

.product__tabs > product__tab-anchor:focus,
.product__tabs > product__tab-anchor[aria-selected='true'] {
  background-color: $white;
  color: #green;
  font-weight: 600;
}

.product__download {
  padding-left: 15px;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;

  @include breakpoint(small only) {
    padding-left: 0;
    padding-top: 10px;
  }
}

.product__download--notabs {
  padding-left: 0;
  margin-bottom: 30px;
}

.product__download-icon {
  width: 25px;
  height: auto;
  margin-right: 15px;
}

.product__download-text {
  text-align: left;
}

.product__about {
  width: 85%;
  margin: 0 auto 40px;
  font-family: 'Open Sans', sans-serif;

  @include breakpoint(xlarge) {
    width: 70%;
  }

  @include breakpoint(small only) {
    width: 90%;
  }

  .about-text {
    @media screen and (min-width: 1160px) {
      padding-right: 20px;
    }
  }
}

.product__catalog-title {
  margin: 20px 0;
  font-weight: 700;

  @include breakpoint(small only) {
    margin-top: 40px;
  }
}

.gallery__container {
  margin: 3rem 0 1rem;
  justify-content: center;

  @include breakpoint(small only) {
    margin: 1.5rem 0 0;
    height: 100%;
  }

  .gallery__cell {
    position: relative;
    border: 1px solid $light-gray;
    border-left: none;
    border-right: none;
  }

  .gallery__cell--middle {
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
  }

  .gallery__cell--right {
    border-left: 1px solid $light-gray;
  }

  .gallery__cell--height {
    height: auto;
    margin-bottom: 10px;
    overflow: hidden;
    border: none;
  }

  .gallery__cell--last {
    @include breakpoint(small only) {
      margin-bottom: 0;
    }
  }

  .gallery__cell--no-border {
    border-bottom: none;
  }

  .gallery__item {
    width: 100%;
    height: auto;
    margin: 0 auto;
    background-color: $white;

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  .gallery__item--50 {
    width: 50%;
    margin-top: 5%;
    align-self: middle;
  }

  .gallery__item--90 {
    position: absolute;
    width: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(small only) {
      position: relative;
    }
  }

  .gallery__item--left {
    align-self: flex-start;
  }
}

.gallery__container--main {
  margin: 3rem 0 0;

  @include breakpoint(small only) {
    margin: 1.5rem 0 0;
    height: 100%;
  }
}